import React from "react";
import "./hero.css";
import phone1 from "../../../assets/images/phone1.png";
import { ActionButtonAndroid } from "../../../components/action-btn-android/index.jsx";
import { ActionButtonIOS } from "../../../components/action-btn-ios/index.jsx";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <div className="icontainer pt-5">
      <br />
      <br />
      <div className="ihero-div mt-1">
        <div className="fluid-container h-100">
          <div className="row h-100">
            <div className="col-sm-12 col-md-7 hero-text-md">
              <h1 className="ihero-text">
                Better banking for you and your loved ones
              </h1>
              <div className="pt-1 d-md-none" />

              <p className="ihero-des">
                iNPAY helps businesses in Africa get paid by{" "}
                <br className="d-none" />
                anyone, anywhere in the world securedly.
              </p>

              <div className="pt-2 d-md-none" />
              <div className="pt-4 d-none d-md-block" />
              <div className="hero-button">
                <Link
                  to="https://play.google.com/store/apps/details?id=ng.inpay.app"
                  className="link-btn"
                >
                  <ActionButtonAndroid />
                </Link>
                <Link
                  to="https://apps.apple.com/ng/app/inpay/id6741436449"
                  className="link-btn"
                >
                  <ActionButtonIOS />
                </Link>
              </div>
            </div>

            <div className="col-sm-12 col-md-5 image-div">
              <div className="pt-4 d-md-none" />
              <img src={phone1} className="phn" alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
