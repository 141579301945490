import React from "react";
import inpay_special_phone_mobile from "../../../assets/images/inpay-special-phone-mobile.png";
import inpay_special_phone from "../../../assets/images/inpay-special-phone.svg";
import "./solution_two.css";
import { ActionButtonAndroid } from "../../../components/action-btn-android";
import { ActionButtonIOS } from "../../../components/action-btn-ios";
import { Link } from "react-router-dom";

function SolutionTwo() {
  return (
    <div className="container pt-5">
      <div className="pt-2"></div>
      <center>
        <div className="inpay-special d-md-none">
          <div className="inpay-special-text-div">
            <p className="inpay-special-text-title"> iNPAY's Special</p>
            <p className="inpay-special-text-subtitle">
              One Solution for all your Cross-Border Payments.
            </p>
            <p className="inpay-special-text-body">
              Download the iNPAY app and enjoy the convenience of making payment
              and making informed decisions right at your fingertips.
            </p>
          </div>

          <div className="inpay-special-text-button">
            <Link
              to="https://play.google.com/store/apps/details?id=ng.inpay.app"
              className="link-btn"
            >
              <ActionButtonAndroid />
            </Link>
            <Link
              to="https://apps.apple.com/ng/app/inpay/id6741436449"
              className="link-btn"
            >
              <ActionButtonIOS />
            </Link>
          </div>
          <div className="image-soln-2-div">
            <img
              className="image-soln-2-div phone-image"
              src={inpay_special_phone_mobile}
              alt=""
            />
          </div>
        </div>
      </center>

      <div className="inpay-special d-none d-md-block">
        <div className="inpay-special-text-div">
          <p className="inpay-special-text-title"> iNPAY's Special</p>
          <p className="inpay-special-text-subtitle">
            One Solution for all your Cross-Border Payments.
          </p>
          <p className="inpay-special-text-body">
            Download the iNPAY app and enjoy the convenience of making payment
            and making informed decisions right at your fingertips.
          </p>
        </div>

        <img className="phone-image" src={inpay_special_phone} alt="" />

        <div className="inpay-special-text-button pt-4">
          <Link
            to="https://play.google.com/store/apps/details?id=ng.inpay.app"
            className="link-btn"
          >
            <ActionButtonAndroid />
          </Link>
          <Link
            to="https://apps.apple.com/ng/app/inpay/id6741436449"
            className="link-btn"
          >
            <ActionButtonIOS />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SolutionTwo;
