import { useEffect } from "react";
import Footer from "../../components/footer";
import HomePageNavbar from "../../components/navbar";
import { getMobileOS } from "../../utils";
import Hero from "../landing/hero";

function DownloadPage() {
  const os = getMobileOS();

  useEffect(() => {
    if (os === "Android") {
      return (window.location.href =
        "https://play.google.com/store/apps/details?id=ng.inpay.app");
    }
    if (os === "iOS") {
      return (window.location.href =
        "https://apps.apple.com/ng/app/inpay/id6741436449");
    }
  }, [os]);

  return (
    <div>
      <HomePageNavbar />
      <Hero />
      <Footer />
    </div>
  );
}

export default DownloadPage;
