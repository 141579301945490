import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

// Import your component pages here
import LandingPage from "./pages/landing";
import ContactPage from "./pages/contact-us/index";
import AboutPage from "./pages/about";
import PrivacyPage from "./pages/legal/privacy";
import TermsServicePage from "./pages/legal/terms-service";
import TermsUsePage from "./pages/legal/terms-use";
import AcceptableUsePage from "./pages/legal/acceptable-use";
import ScrollToTop from "./components/scroll-to-top";
import DataDeletionPage from "./pages/data-deletion";
import SupportPage from "./pages/support";
import DownloadPage from "./pages/download";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/contact-support" element={<SupportPage />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/terms-service" element={<TermsServicePage />} />
        <Route path="/terms-use" element={<TermsUsePage />} />
        <Route path="/acceptable-use" element={<AcceptableUsePage />} />
        <Route path="/data-deletion" element={<DataDeletionPage />} />
        <Route path="/download" element={<DownloadPage />} />
      </Routes>
    </Router>
  );
}

export default App;
