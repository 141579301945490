// import Footer from "../../components/footer";
import HomePageNavbar from "../../components/navbar";
import SupportBody from "./body";
import Header from "./header/index";

function SupportPage() {
  return (
    <div>
      <HomePageNavbar />
      <Header />
      <SupportBody />
    </div>
  );
}

export default SupportPage;
